.central-image {
    margin-top: 1rem;
    text-align: center;
    cursor: 'pointer';
}

.central-image img {
    max-width: 380px;
    cursor: pointer;
    border: 2px solid #ccc;
    border-radius: 10px;
    width: 99%;
}

.image-note {
    font-size: 0.9rem;
    color: #666;
    margin: 0%;
}

.villain-wrap-controls-container {
    display: flex;
    justify-content: center; /* Centra el contenedor de botones horizontalmente */
    align-items: center; /* Centra el contenedor de botones verticalmente */
    height: 11vh; /* Ocupa el 15% de la altura de la vista del navegador */
}

.villain-controls-container {
    display: flex;
    justify-content: center; /* Centra el contenedor de botones horizontalmente */
    align-items: center; /* Centra el contenedor de botones verticalmente */
    height: 11vh; /* Ocupa el 15% de la altura de la vista del navegador */
}
.villain-controls {
    display: flex;
    justify-content: center; /* Centra los botones horizontalmente */
    align-items: center; /* Alinea los botones verticalmente en el centro */
    gap: 10px; /* Espacio entre los botones */
}

.villain-controls button {
    margin: 5px;
    padding: 5px 5px;
    font-size: 16px;
    cursor: pointer;
}

.seccion-boton Button {
    margin: 8px;
}


.day-button{
    margin: 12px;
}

.seccion-boton-atras {
    top: 10px; /* Ajusta el valor según lo necesites */
    left: 10px; /* Ajusta el valor según lo necesites */
}