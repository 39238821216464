body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .encuentroEspecialApp {
    padding: 10px;
  }

  .seccion-boton-atras {
    padding: 10px;
}

  .boton-volverAventura {
      background-color: white; /* Fondo blanco */
      color: black; /* Color del texto */
      border: 2px solid #ccc; /* Borde gris claro */
      border-radius: 8px; /* Bordes redondeados */
      padding: 10px 15px; /* Espaciado interior */
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Sombra */
      font-size: 16px; /* Tamaño de fuente */
      cursor: pointer; /* Cursor en forma de mano para mostrar que es clicable */
      display: flex; /* Flexbox para alinear el texto e icono */
      align-items: center; /* Alinear verticalmente el texto e icono */
  }

  .boton-volverAventura:hover {
      background-color: #f0f0f0; /* Cambio de fondo al pasar el mouse */
  }

  .boton-volverAventura:focus {
      outline: none; /* Quita el borde azul al hacer click */
  }
  
  .category {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    margin: 5px 0;
    border-radius: 4px;
  }
  
  .category-header {
    padding: 10px;
    cursor: pointer;
    position: relative;
    background-color: #e0e0e0;
    font-weight: bold;
  }
  
  .toggle-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
  }
  
  .items {
    padding-left: 20px;
    margin-right: 3%;
  }
  
  .item {
    padding: 10px;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #ddd;
  }
  
  .item:hover {
    background-color: #e0e0e0;
  }

  .central-image {
    position: relative; /* Asegura que los elementos dentro de este contenedor puedan posicionarse de forma absoluta */
  }

  .confirmation-box {
    position: absolute;
    top: 50%; /* Centra el cuadro en la mitad de la imagen */
    left: 50%;
    transform: translate(-50%, -50%); /* Ajusta para centrar el cuadro en ambas direcciones */
    padding: 50px;
    border: 2px solid green;
    background-color: white;
    color: green;
    font-weight: bold;
    text-align: center;
    z-index: 10; /* Asegura que el cuadro se muestre por encima de otros elementos */
    animation: shine 0.5s ease-in-out, fadeOut 4.5s forwards;
    white-space: nowrap; /* Evita que el texto se desborde */
  }

  .add-button {
    background-color: rgb(203 152 122 / 73%);
    border: 2px solid black;
    border-radius: 5px; /* Para bordes redondeados */
    padding: 5px 10px; /* Ajusta el padding si es necesario */
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center; /* Centra el contenido verticalmente */
    width: 250px; /* Fija el ancho para que todos los botones sean iguales */
    height: 50px;
    color: black;

}

  /* Animación para el brillo/parpadeo */
  @keyframes shine {
      0% {
          box-shadow: 0 0 10px green;
      }
      50% {
          box-shadow: 0 0 20px green;
      }
      100% {
          box-shadow: 0 0 10px green;
      }
  }

  /* Animación para desvanecer */
  @keyframes fadeOut {
      0% {
          opacity: 1;
      }
      100% {
          opacity: 0;
      }
  }