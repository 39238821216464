.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .logo-image{
    padding-top: 20px;
    text-align: center;
    padding-bottom: 40px;
    margin-bottom: 40px;
    background-color: black;
  }

  .logo-image img{
    max-width: 300px;
    width: 80%;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .villain-selector{
    width: 90%;
  }
  
  .seccion-boton{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  .boton-aventura{
    min-height: 50px;
  }

  .boton-aventura-text {
    font-size: 20px;
  }
  
  .empty-notification{
    position: fixed;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #df1e1e;
    color: white;
    padding: 20px;
    border-radius: 5px;
    font-size: 16px;
    z-index: 1000;
    opacity: 0.9;
  }
  
  .titulo h1 {
    text-align: center;
    font-size: 2.8em;
    margin: 0;
  }

  .subtitulo {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 80px;
  }

  h3 {
    font-size: 1.5em;
    margin: 0;
  }

  .footer {
    margin-top: 64px;
    padding: 15px;
    text-align: right;
  }

  .arrows-container {
    display: flex;
    align-items: center; /* Aligns children vertically in the middle */
    justify-content: center; /* Centers children horizontally */
    gap: 10px; /* Adds space between the elements */
    flex-wrap: nowrap; /* Prevents the internal elements from wrapping */
}

  .arrows {
    padding-bottom: 20px;
    cursor: pointer; /* Adds a pointer cursor to the arrows */

  }

  .avatar-name {
    margin-top: 8px;
    
  }

  .avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stack-container {
    display: flex;
    flex-wrap: wrap; /* Allows the elements to wrap to the next line if they don't fit */
    gap: 20px; /* Adds space between the wrapped rows */
    justify-content: center; /* Centers the wrapped rows horizontally */
    margin-bottom: 80px;
}



/* Media query for mobile screens */
@media (max-width: 768px) {
  .stack-container {
      flex-direction: column; /* Stack the arrows-container elements vertically */
      align-items: center; /* Center the elements horizontally */
      height: auto; /* Adjust height for mobile screens */
      margin-bottom: 80px;
    }

  .arrows-container {
      width: 100%; /* Ensure the arrows-container takes full width */
  }
}


