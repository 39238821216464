.scrollable-content {
    max-height: 70vh;
    overflow-y: auto;
    padding: 16px;
    margin-bottom: 16px;
}

.important {
    font-weight: bold;
    color: red;
}