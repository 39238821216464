.faq-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
    text-align: justify;
}

.faq-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.faq-section {
    margin-bottom: 20px;
}

.faq-section h3 {
    color: #007bff;
    margin-bottom: 10px;
}

.faq-section h4 {
    color: black;
    margin-bottom: 10px;
}

.faq-section p {
    line-height: 1.6;
}

.faq-section ul {
    list-style-type: disc;
    padding-left: 20px;
}
.faq-section li {
    margin-top: 20px;
}

.seccion-boton {
    text-align: center;
    margin-top: 30px;
}

.boton-aventura {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.boton-aventura:hover {
    background-color: #0056b3;
}

.faq-section p img {
    width: 100%;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
}

.image-row {
    display: flex;
    justify-content: space-around; /* Adjusts the space between the images */
    align-items: center; /* Aligns the images vertically in the center */
    gap: 20px; /* Adds space between the images */
}

.image-row img {
    max-width: 100%; /* Ensures the images are responsive */
    height: auto; /* Maintains the aspect ratio of the images */
}