.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    background-size: cover;
    padding: 10px;
    position: relative;
  }
  
  .logo img {
    height: 50px; /* Ajusta el tamaño del logo si es necesario */
    margin-right: 40px;
  }

  .logo {
    display: flex;
    justify-content: center; /* Center the logo horizontally */
    align-items: center;
  }
  
  .header-fields {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .field {
    color: white;
    font-size: 16px;
  }
  
  .menu-toggle {
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #FFBF00;
    z-index: 1;
    text-shadow: 3px 1px 4px rgb(0 0 0 / 95%);
  }
  
  .menu {
    position: fixed;
    top: 0;
    right: -70%;
    width: 70%;
    max-width: 500px;
    height: 100%;
    background: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    transition: right 0.3s ease;
    overflow-y: auto;
    z-index: 1200;
  }
  
  .menu.open {
    right: 0;
  }
  
  .menu .close-button {
    padding: 15px;
    font-size: 35px;
  }
  
  .menu nav ul {
    list-style: none;
    padding: 0;
  }
  
  .menu nav ul li {
    padding: 15px;
  }
  
  .menu nav ul li a {
    text-decoration: none;
    color: black;
  }
  
  .field {
    color: white;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo negro semi-transparente por defecto */
    padding: 5px;
    border-radius: 5px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Sombra de texto */
    transition: background-color 0.5s ease-in-out; /* Transición suave de fondo */
  }
  
  /* Clase para cambiar el fondo a verde */
  .field.green-background {
    background-color: green;
  }
  
  /* Clase para cambiar el fondo a rojo */
  .field.red-background {
    background-color: red;
  }
  
  /*Estilos CSS para el Aviso de guardado */
  .save-notification {
  position: fixed;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 20px;
  border-radius: 5px;
  font-size: 20px;
  z-index: 1000;
  opacity: 0.9;
  text-align: justify;
  }
  
  .menu ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: 20px;
  }
  
  .menu ul li a img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
