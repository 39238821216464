.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
  }
  
  .message-box {
    padding: 10px 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    width: 80%;
    max-width: 400px;
  }
  
  .valid {
    background-color: #d4edda; /* Fondo verde claro */
    color: #155724; /* Texto verde oscuro */
    border: 1px solid #c3e6cb;
  }
  
  .invalid {
    background-color: #f8d7da; /* Fondo rojo claro */
    color: #721c24; /* Texto rojo oscuro */
    border: 1px solid #f5c6cb;
  }
  
  label {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  textarea {
    width: 80%;
    max-width: 400px;
    height: 100px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
