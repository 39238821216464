.expansion-item-checkbox {
    margin: 15px;
    border: 4px solid transparent;
    cursor: pointer;
    width: 150px;
    height: 150px;
    border-radius: 22px;
    text-align: center;
    padding: 3px;
    position: relative;
    display: inline-block;
}

.expansion-item-checkbox.selected {
    border-color: #2196f3; /* Borde azul al pasar el ratón por encima */
}

.icon-overlay {
    opacity: 0%;
}

.icon-overlay.selected {
    opacity: 100%;
    position: absolute;
    bottom: -8px;
    right: -8px;
    width: 24px; /* Ajusta el tamaño según sea necesario */
    height: 24px;
}

.expansion-image-checkbox {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.icon-overlay {
    position: absolute;
    bottom: -6px;
    right: -6px;
    width: 24px; /* Ajusta el tamaño según sea necesario */
    height: 24px;
}

.expansion-selector {
    text-align: center;
    margin-bottom: 80px;
}

.expansion-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.expansion-item {
    margin: 15px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: transform 0.2s, border-color 0.2s;
    width: 150px;
    height: 150px;
    filter: grayscale(100%); /* Hace la imagen blanco y negro */
    opacity: 0.6; /* Reduce la opacidad */
    border-radius: 10px;
    text-align: center;
    padding: 0.5rem; /* Espaciado interno para el texto */
}

.expansion-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: filter 0.2s, opacity 0.2s; /* Transiciones suaves */
}

.expansion-item.selected {
    filter: grayscale(0%); /* Devuelve la imagen a color */
    opacity: 1; /* Opacidad completa */
    transform: scale(1.1); /* Efecto de agrandado */
}

.expansion-item:hover {
    border-color: #007bff; /* Borde azul al pasar el ratón por encima */
}

.expansion-text {
    display: block;
    margin-top: 0.5rem;
    font-size: 0.9rem; /* Tamaño del texto */
    color: #333;
    text-align: center;
    font-weight: bold; /* Negrita para destacar el texto */
}