.player-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    margin-bottom: 32px;
}

.player-selector-chip {
    width: 100px;
    height: 50px !important;
    margin: 0px 30px 0px 30px;
}

.player-selector-chip span {
    font-size: 25px;
}

.player-selector-input {
    width: 100px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin: 0 10px;
    border: 2px solid #ddd;
    border-radius: 5px;
    background-color: #f8f9fa;
    outline: none;
}

.player-selector-box{
    text-align: center;
}