.villain-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 80px;
}

.villain-selector {
    display: flex;
    justify-content: left;
    gap: 1rem;
}

.villain-categories {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.category-button {
    height: 50px !important;
    margin-left: 8px !important;
}

.category-button:first-child {
    margin-left: 16px !important;
}

.category-button span{
    font-size: 18px;
}

.category-button.selected {
    background-color: #353535;
    color: white;
}

.category-button:hover {
    background-color: #353535 !important;
    color: white;
}

.villain-list-container {
    display: flex;
    overflow-x: auto; /* Habilita el desplazamiento horizontal */
    white-space: nowrap; /* Evita que los elementos se envuelvan a la siguiente línea */
    max-width: 100%; /* Asegura que el contenedor no exceda el ancho de su contenedor padre */
    scrollbar-width: none; /* Oculta la barra de desplazamiento en Firefox */
    cursor: grab; /* Cambia el cursor a una mano abierta */
}

.villain-list-container:active {
    cursor: grabbing; /* Cambia el cursor a una mano cerrada cuando se arrastra */
}


.villain-list-container::-webkit-scrollbar {
    display: none; /* Oculta la barra de desplazamiento en Chrome, Safari y Opera */
}

.villain-list-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Color de la barra de desplazamiento */
    border-radius: 10px; /* Bordes redondeados */
}

.villain-list-container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color de la barra de desplazamiento al pasar el ratón */
}

.villain-list {
    display: inline-flex; /* Asegura que los elementos se dispongan en una fila */
    gap: 10px; /* Espacio entre los chips */
}

.villain-button {
    height: 40px !important;
    margin-left: 8px !important;
}

.villain-button:first-child {
    margin-left: 16px !important;
}

.villain-button span{
    font-size: 18px;
}

.villain-button.selected {
    background-color: #353535;
    color: white;
}
.villain-button:hover {
    background-color: #353535 !important;
    color: white;
}

.villain-image-container {
    margin-top: 1rem;
    text-align: center;
    margin-left: 16px;
    margin-right: 16px;
}

.villain-image {
    max-width: 400px;
    cursor: pointer;
    border: 2px solid #ccc;
    border-radius: 10px;
    margin-bottom: 8px;
    width: 99%;
}

.image-note {
    font-size: 0.9rem;
    color: #666;
    margin-top: 0.5rem;
}

.villain-controls-container {
    display: flex;
    justify-content: center; /* Centra el contenedor de botones horizontalmente */
    align-items: center; /* Centra el contenedor de botones verticalmente */
    height: 7vh; /* Ocupa el 15% de la altura de la vista del navegador */
}
.villain-controls {
    display: flex;
    justify-content: center; /* Centra los botones horizontalmente */
    align-items: center; /* Alinea los botones verticalmente en el centro */
    gap: 10px; /* Espacio entre los botones */
}

.villain-controls button {
    margin: 5px;
    padding: 5px 5px;
    font-size: 16px;
    cursor: pointer;
}

.tools {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.tools .tool {
    margin-left: 10px;
}

.villain-categories-list {
    display: flex;
    overflow-x: auto; /* Habilita el desplazamiento horizontal */
    white-space: nowrap; /* Evita que los elementos se envuelvan a la siguiente línea */
    gap: 8px; /* Espacio entre los chips */
    max-width: 100%; /* Asegura que el contenedor no exceda el ancho de su contenedor padre */
    scrollbar-width: none; /* Oculta la barra de desplazamiento en Firefox */
    margin-top: 8px;
}

.villain-categories-list::-webkit-scrollbar {
    display: none; /* Oculta la barra de desplazamiento en Chrome, Safari y Opera */
}

.villain-categories-list::-webkit-scrollbar-thumb {
    background-color: #888; /* Color de la barra de desplazamiento */
    border-radius: 10px; /* Bordes redondeados */
}

.villain-categories-list::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color de la barra de desplazamiento al pasar el ratón */
}
