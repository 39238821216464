.typing-effect-container {
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 999;

}

.typing-effect-textarea {
    width: 260px;
    height: 60%;
    font-size: 16px;
    line-height: 1.5;
    padding: 10px;
    border-radius: 5px;
    border: transparent;
    background-color: transparent; /* Fondo semi-transparente */
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    resize: none; /* Desactiva el redimensionamiento */
    overflow: hidden; /* Oculta el desbordamiento de texto */
    font-family: Arial, sans-serif; /* Fuente para evitar problemas de presentación */
}
