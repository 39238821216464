.title-box{
    text-align: center;
    margin: 10px 0;
}

.image-container{
    position: relative;
    text-align: center;
}

.image-container.old img {
    filter: grayscale(100%);
}

.indicador-dia{
    position: absolute;
    top: -30px; /* Ajusta la distancia que quieras entre el icono y la imagen */
    left: 50%;  /* Centra el icono horizontalmente */
    transform: translateX(-50%); /* Centra el icono exactamente en el medio */
    z-index: 300;
}
.indicador-dia img{
    height: 80px;
}

.central-image {
    margin-top: 1rem;
    text-align: center;
    cursor: 'pointer';
}

.central-image img {
    cursor: pointer;
    border: 2px solid #ccc;
    border-radius: 10px;
}
.central-image.espacio {
    margin-top: 30px;
}

.image-note {
    font-size: 0.9rem;
    color: #666;
    margin: 0%;
}

.controls-container {
    display: flex;
    justify-content: center; /* Centra el contenedor de botones horizontalmente */
    align-items: center; /* Centra el contenedor de botones verticalmente */
    height: 11vh; /* Ocupa el 15% de la altura de la vista del navegador */
}
.controls {
    display: flex;
    justify-content: center; /* Centra los botones horizontalmente */
    align-items: center; /* Alinea los botones verticalmente en el centro */
    gap: 10px; /* Espacio entre los botones */
    max-width: 99%;
}

.controls button {
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    border: 0px !important;
    min-width: 9em !important;
    min-height: 50px !important;
    font-weight: bold;
}


.transparent-button {
    background-color: white;
    border: 2px solid black;
    border-radius: 5px; /* Para bordes redondeados */
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center; /* Centra el icono horizontalmente */
    align-items: center; /* Centra el icono verticalmente */
    transition: background-color 0.2s ease, transform 0.1s ease, box-shadow 0.2s ease; /* Añade transiciones */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para darle profundidad */
}

.transparent-button img {
    max-width: 60px; /* Ajusta el tamaño del icono */
    max-height: 60px;
}

.transparent-button:active {
    background-color: #e0e0e0; /* Color más oscuro al presionar */
    transform: scale(0.95); /* Reduce ligeramente el tamaño */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra más pequeña para efecto de "presionado" */
}

.day-button {
    background-color: #007bff; /* Color de fondo del botón */
    border-radius: 5px; /* Para bordes redondeados */
    padding: 5px 10px; /* Ajusta el padding si es necesario */
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center; /* Centra el contenido verticalmente */
    width: 100px; /* Fija el ancho para que todos los botones sean iguales */
    height: 50px;
    color: white;
}
.day-button:active {
    background-color: #002c5a; /* Color de fondo del botón */
    transform: scale(0.95); /* Reduce ligeramente el tamaño */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra más pequeña para efecto de "presionado" */
}

button:disabled {
    background-color: #b3b3b3; /* Color gris para el fondo */
    color: #ffffff; /* Color blanco para el texto */
    cursor: not-allowed; /* Cursor indicando que no es clicable */
    border: 1px solid #999; /* Borde más claro */
  }

.question-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid black;
    padding: 40px;
    z-index: 1100; /* Aumenta el valor de z-index para asegurarte de que esté al frente */
    text-align: center;
    width: 350px;
    height: 630px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: normal; /* Distribuye el contenido verticalmente */
    align-items: center;
}

.mini-image {
    margin-bottom: 20px; /* Añade un margen inferior para separar la imagen del texto */
}

.mini-image img {
    height: 180px;
    cursor: pointer;
    border: 2px solid #ccc;
    border-radius: 10px;
}
.mini-image-note {
    font-size: 0.6rem;
    color: #666;
    margin: 0%;
}

.question-box p {
    font-size: 20px; /* Tamaño de la fuente del texto */
    font-weight: bold;
    margin: 20px 0; /* Añade un margen para separar el texto de la imagen y los botones */

}

.button-group {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    align-items: center; /* Center the buttons vertically */
    gap: 10px; /* Add space between the buttons */
}

.button-group button {
    padding: 10px 20px; /* Add padding to the buttons */
    border: none; /* Remove default border */
    background-color: #007bff; /* Set background color */
    color: white; /* Set text color */
    cursor: pointer; /* Change cursor to pointer */
    border-radius: 5px; /* Add border radius */
    font-size: 16px; /* Set font size */
}

.question-box button {
    padding: 15px 30px; /* Tamaño del botón */
    cursor: pointer;
    font-size: 18px; /* Tamaño de la fuente del botón */
    background-color: #007bff; /* Color de fondo del botón */
    color: white; /* Color del texto del botón */
    border: none;
    border-radius: 5px; /* Bordes redondeados para el botón */
    margin-top: 15px;
}

.question-box button:hover {
    background-color: #0056b3; /* Color de fondo al hacer hover en el botón */
}

.close-expand-image{
    position: fixed;
    top: 10%;
    right: 15%;
    z-index: 1300;
}

.close-expand-image-doc {
    position: absolute;
    top: 10px; /* Ajusta según sea necesario */
    right: 10px; /* Ajusta según sea necesario */
}

.expand-image-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(56 56 56 / 73%);
    border: 2px solid black;
    padding: 40px;
    z-index: 1100;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: normal; /* Distribuye el contenido verticalmente */
    align-items: center;
}
.expand-image img{
    margin-top: 100px;
    max-width: 400px;
    cursor: pointer;
    border: 2px solid #ccc;
    border-radius: 10px;
}

.top-info {
    margin-top: 20px;
    text-align: center; /* Centra el texto */
    display: flex;
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center; /* Centra el contenido verticalmente */
}

.chip-progreso {
    margin-right: 10px;
}

.chip-progreso span{
    font-size: 18px;
}

.chip-dias {
    margin-right: 10px;
}

.chip-dias span{
    font-size: 18px;
}

.chip-bq {
    margin-right: 10px;
}

.chip-bq span{
    font-size: 18px;
}

.info-value {
    margin-top: 10px;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -3px; /* Ajusta este valor según sea necesario */
}

.tools {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

.tools .tool {
    margin-left: 10px;
}

.progreso-chip-control {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 24px;
}

.progreso-chip-control h3 {
    text-align: center !important;
}

.progreso-control {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.progreso-control button {
    border: 0px;
}

.question-box-rules {
    position: fixed;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 40px;
    z-index: 1100; /* Aumenta el valor de z-index para asegurarte de que esté al frente */
    text-align: center;
    max-width: 350px;
    max-height: 430px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: normal; /* Distribuye el contenido verticalmente */
    align-items: center;
}

.autocomplete {
    margin-top: 16px;
    justify-content: center !important;
    display: flex
;
}

.rules-info {
    margin-top: 20px;
    text-align: center; /* Centra el texto */
    display: flex;
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center; /* Centra el contenido verticalmente */
}

.close-button {
    padding: 15px;
    font-size: 35px !important;
  }

  .expand-image-container h3 {
    font-size: 32px;
    margin-bottom: 12px;
  }

  .avatar-wrapper {
    position: relative;
    display: inline-block;
}

.avatar-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 64px;
    font-weight: bold;
    pointer-events: none; /* Ensure the number doesn't interfere with avatar interactions */
}

.batalla {
    align-items: center;
    justify-content: center;
    margin: 40px auto 40px auto;
    max-width: 380px;
}

.batalla nav {
    border-radius: 10px;
}

.batalla-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.batalla span {
    font-size: 18px;
}

.batalla svg {
    color: #e0e0e0 !important;
}

.modal-rules {
    max-width: 380px !important;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: center !important;
}

.modal-rules h3 {
    text-align: center !important;
    justify-content: center !important;
}

.autocomplete div {
    max-width: 380px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: center !important;
}

.seccion-boton-cerrar{
    display: flex;
    align-items: center;
    justify-content: center;
  }