.revisar-proximos-encuentros {
    position: relative;
}

.imagenes-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px; /* Espacio entre las imágenes */
}

.imagen-item {
    position: relative;
    text-align: center;
}
.imagen-item p{
    font-size: 20px;
}

.miniatura-imagen {
    max-width: 200px; /* Tamaño adecuado para la miniatura */
    cursor: pointer;
    border: 2px solid #ccc;
    border-radius: 10px;
}

.zoom-icon {
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 5px;
}

.zoom-icon img {
    width: 24px; /* Tamaño del ícono de lupa */
    height: 24px;
}

.flechas-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Espacio entre las flechas */
    margin-top: 20px;
}

.flecha {
    background: none;
    border: none;
    font-size: 24px; /* Tamaño de las flechas */
    background-color: #007bff; /* Color de fondo del botón */
    color: white; /* Color del texto del botón */
    cursor: pointer;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
}

.modal-content img {
    max-width: 90vw; /* Ajusta el tamaño de la imagen en el modal */
    max-height: 90vh;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;
    background-color: #007bff; /* Color de fondo del botón */
    color: white; /* Color del texto del botón */
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;
}

