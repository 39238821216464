.central-image {
    height: auto; /* Asegúrate de que la imagen se ajuste al contenedor */
    margin-left: 16px;
    margin-right: 16px;

}

.villain-image {
    width: 99% !important;
    height: auto; /* Ajusta la imagen según el contenedor */
}

.titulo-prologo {
    position: absolute;
    top: 50px; /* Ajusta la posición vertical del título */
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; /* Asegura que el título esté encima del texto */
    color: #610000; /* Color del texto del título */
    font-size: 2rem; /* Tamaño de la fuente del título */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Añade una sombra para destacar el título */
}

.typing-effect-container {
    position: absolute; /* Asegura que el texto se superponga sobre la imagen */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centra el texto en el contenedor */
    width: 80%; /* Ajusta el ancho según sea necesario */
    text-align: center;
    pointer-events: none; /* Opcional: Desactiva los eventos de puntero para evitar interferencias */
}

.typing-effect-textarea {
    width: 100%;
    height: auto; /* Ajusta la altura según el contenido */
    font-size: 16px;
    line-height: 1.5;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: rgba(255, 255, 255, 0.8); /* Fondo semi-transparente */
    resize: none; /* Desactiva el redimensionamiento */
    overflow: hidden; /* Oculta el desbordamiento de texto */
    font-family: Arial, sans-serif; /* Fuente para evitar problemas de presentación */
    white-space: pre-wrap; /* Mantiene los espacios y saltos de línea */
}
.texto-prologo{
    z-index: 1000;
}

.tool {
    border: 0px !important;
}